<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :master-tenant-options="masterTenantOptions"
      :desabled="desabled"
      v-if="showFilters"
    />

    <!-- Breadcrumbs -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
      v-if="showFilters"
    />
    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast" />

    <!-- Filters -->
    <filters
      :master-tenant-filter.sync="masterTenantFilter"
      :master-tenant-options="masterTenantOptions"
      v-if="showFilters"
    />

    <!-- Table Container Card -->
    <b-card :class="{ 'm-2': showFilters, 'm-0': !showFilters }" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              data-cy="automatest-v-select-pagination-control"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="5" v-if="showFilters">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                data-cy="search"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                @click="openAdd(), addDesabled()"
                v-if="$can('create', 'Tenant')"
              >
                <span class="text-nowrap text-capitalize">{{
                  $t("actions.add") + " " + $tc("tenants.title", 1)
                }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
                <span class="text-nowrap text-capitalize"> </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(routers_count)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(created)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(updated)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(image)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(logo_image)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(app_image)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: Image -->
        <template #cell(image)="data">
          <div class="hoverable">
            <b-img
              :src="imageUrl + (data.item.image ? '?path=' + data.item.image : '')"
              fluid
              alt="Tenant Image"
              style="max-height: 50px"
            ></b-img>
          </div>
        </template>

        <!-- Column: Image -->
        <template #cell(app_image)="data">
          <div class="hoverable">
            <b-img
              :src="
                imageAppUrl + (data.item.app_image ? '?path=' + data.item.app_image : '')
              "
              fluid
              alt="Tenant Image"
              style="max-height: 50px"
            ></b-img>
          </div>
        </template>

        <!-- Column: Image -->
        <template #cell(logo_image)="data">
          <div class="hoverable">
            <b-img
              :src="
                imageLogoUrl +
                (data.item.logo_image ? '?path=' + data.item.logo_image : '')
              "
              fluid
              alt="Tenant Image"
              style="max-height: 50px"
            ></b-img>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="showFilters">
          <b-button
            :id="`actions-${data.item.id}`"
            tabindex="0"
            variant="white"
            class="px-1 btn-y"
          >
            <feather-icon
              data-cy="actions"
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </b-button>
          <b-popover
            :target="`actions-${data.item.id}`"
            triggers="focus"
            placement="bottom"
            custom-class="popover-p-0"
            variant="white"
          >
            <b-list-group class="m-0">
              <b-list-group-item
                @click="openEdit(data.item), editDesabled()"
                v-if="$can('update', 'Tenant')"
                class="border-0"
                button
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $t("actions.edit") }}
                </span>
              </b-list-group-item>
              <b-list-group-item
                @click="confirmDelete(data.item.id)"
                v-if="$can('delete', 'Tenant')"
                class="border-0"
                button
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $t("actions.delete") }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            data-cy="pagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { computed } from "vue2-dropzone";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";

export default {
  components: {
    Sidenav,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
    Breadcrumb,
    ToastificationLoadingContent,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start export
    const loadingToast = ref(null);
    const action = ref("list");
    const { t, tc } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
      "tenant.title": tc("tenants.title", 1),
      report_of: t("messages.export.report"),
      full_download: t("messages.export.full_download"),
    };
    const exportExcel = () => {
      action.value == "export";
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("tenant/export", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_master_tenant: masterTenantFilter.value,
          action: "export",
        })
        .then((response) => {
          console.log(response);
          function id() {
            return store.getters["tenant/getIdNotification"];
          }
          function url() {
            return store.getters["tenant/getUrl"];
          }

          const notification = {
            id: id(),
            progress: 100,
            completed: true,
            view: "tenants",
            url: url(),
            download: id(),
            title: `${trans["report_of"]} ${trans["tenant.title"]}`,
            body: trans["full_download"],
            error: false,
            timestamp:
              new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
          };
          store.dispatch("grid/updateDownloadNotification", notification);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };
    const buttonExport = ref(false);
    // end export

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.tenants.all_tenants");
    // end breadcrumbs

    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const desabled = ref(false);
    const editDesabled = function () {
      desabled.value = true;
    };
    const addDesabled = function () {
      desabled.value = false;
    };

    const imageUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/main`;
    const imageLogoUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/logo`;
    const imageAppUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/app`;

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };

    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      masterTenantOptions,
      masterTenantFilter,
      applyChartFilters,
    } = useList(props);

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          loadingToast.value.show("Eliminando", "En progreso...", "secondary");
          storeInner
            .dispatch("tenant/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            })
            .finally(() => {
              loadingToast.value.close();
            });
        }
      });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    applyChartFilters(props.filters);

    return {
      loadingToast,
      action,
      exportExcel,
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      masterTenantOptions,
      masterTenantFilter,
      imageUrl,
      imageLogoUrl,
      imageAppUrl,
      desabled,
      editDesabled,
      addDesabled,
    };
  },
};
</script>



<style lang="scss">
.hoverable:hover {
  background: gray;
}
</style>
