<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("tenants.title", 1)
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Master Tenant -->
          <validation-provider
            #default="validationContext"
            name="Tenant"
            rules="required"
          >
            <b-form-group
              data-cy="tenant"
              :label="$tc('master_tenants.title')"
              label-for="id_master_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_master_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="masterTenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_master_tenant"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('tenants._name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('Description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- active -->
          <validation-provider name="active" rules="required">
            <b-form-checkbox
              id="active"
              v-model="itemData.active"
              name="active"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t("active") }}
            </b-form-checkbox>
          </validation-provider>

          <label class="mb-2 text-capitalize">{{
            $t("tenants.main_image") + ` max size (1200x800)`
          }}</label>

          <vue-dropzone
            id="dropzone"
            ref="dropzoneImage"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFileImage"
            @vdropzone-removed-file="removeFileImage"
          />

          <label class="my-2 text-capitalize">{{
            $t("tenants.logo_image") + ` max size (800x800)`
          }}</label>

          <vue-dropzone
            id="dropzone_logo"
            ref="dropzoneImageLogo"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFileImageLogo"
            @vdropzone-removed-file="removeFileImageLogo"
          />

          <label class="my-2 text-capitalize">{{
            $t("tenants.image_mobile_app") + ` max size (1200x800)`
          }}</label>

          <vue-dropzone
            id="dropzone_app"
            ref="dropzoneImageApp"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFileImageApp"
            @vdropzone-removed-file="removeFileImageApp"
          />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BImg,
    vueDropzone: vue2Dropzone,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    masterTenantOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    desabled: { type: Boolean, required: true },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, context) {
    const { t } = useI18nUtils();
    const trans = {
      "messages.edit_save.successfully_created": t(
        "messages.edit_save.successfully_created"
      ),
      "messages.edit_save.updated_successfully": t(
        "messages.edit_save.updated_successfully"
      ),
    };
    const blankData = {
      name: "",
      description: "",
      id_master_tenant: null,
      active: "1",
      image: null,
      logo_image: null,
      app_image: null,
    };

    const imageUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/main`;
    const imageLogoUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/logo`;
    const imageAppUrl = `${process.env.VUE_APP_BASE_URL}/v1/tenant/image/app`;

    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      paramName: "image",
      autoProcessQueue: false,
      dictDefaultMessage: "",
      addRemoveLinks: true,
      dictRemoveFile: t("actions.delete"),
      acceptedFiles: "image/*",
    });

    const dropzoneImage = ref(null);
    const dropzoneImageFile = ref(null);
    const hasImage = computed(() => !!itemData.value.image);

    const addFileImage = (file) => {
      dropzoneImageFile.value = file;
    };

    const removeFileImage = () => {
      dropzoneImageFile.value = null;
    };

    const dropzoneImageLogo = ref(null);
    const dropzoneImageFileLogo = ref(null);
    const hasImageLogo = computed(() => !!itemData.value.logo_image);

    const addFileImageLogo = (file) => {
      dropzoneImageFileLogo.value = file;
    };

    const removeFileImageLogo = () => {
      dropzoneImageFileLogo.value = null;
    };

    const dropzoneImageApp = ref(null);
    const dropzoneImageFileApp = ref(null);
    const hasImageApp = computed(() => !!itemData.value.app_image);

    const addFileImageApp = (file) => {
      dropzoneImageFileApp.value = file;
    };

    const removeFileImageApp = () => {
      dropzoneImageFileApp.value = null;
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));

      dropzoneImage.value.removeAllFiles();
      dropzoneImageLogo.value.removeAllFiles();
      dropzoneImageApp.value.removeAllFiles();
    };

    const onSubmit = () => {
      let dispatch;

      const data = new FormData();
      data.append("name", itemData.value.name);
      data.append("description", itemData.value.description);
      data.append("id_master_tenant", itemData.value.id_master_tenant);
      data.append("active", itemData.value.active);

      if (dropzoneImage.value.getAcceptedFiles().length > 0) {
        if (dropzoneImage.value.getAcceptedFiles()[0] instanceof File)
          data.append("image", dropzoneImage.value.getAcceptedFiles()[0]);
        else data.append("image", "");
      }

      if (dropzoneImageLogo.value.getAcceptedFiles().length > 0) {
        if (dropzoneImageLogo.value.getAcceptedFiles()[0] instanceof File)
          data.append("logo_image", dropzoneImageLogo.value.getAcceptedFiles()[0]);
        else data.append("logo_image", "");
      }

      if (dropzoneImageApp.value.getAcceptedFiles().length > 0) {
        if (dropzoneImageApp.value.getAcceptedFiles()[0] instanceof File)
          data.append("app_image", dropzoneImageApp.value.getAcceptedFiles()[0]);
        else data.append("app_image", "");
      }

      if (props.isAdd) {
        dispatch = store.dispatch("tenant/add", data);
      } else {
        const req = {
          id: itemData.value.id,
          params: data,
        };

        dispatch = store.dispatch("tenant/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: props.isAdd
                  ? response.message
                  : trans["messages.edit_save.updated_successfully"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
            context.emit("refetch-data");
            context.emit("update:is-sidebar-active", false);
            dropzoneImage.value.removeAllFiles();
            dropzoneImageLogo.value.removeAllFiles();
            dropzoneImageApp.value.removeAllFiles();
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``;
            Object.entries(response.response.data.data).forEach((entry) => {
              const [key, value] = entry;
              string += `${key} - ${value}<br>`;
            });
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string,
              },
            });
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;

        if (itemData.value.image) {
          let imageName = itemData.value.image.split("/")[1];
          let imageType = itemData.value.image.split(".")[1];

          let file = {
            size: 123,
            name: imageName,
            type: `image/${imageType}`,
            accepted: true,
          };
          let url = imageUrl + "?path=" + itemData.value.image;
          dropzoneImage.value.manuallyAddFile(file, url);
        }

        if (itemData.value.logo_image) {
          let imageNameLogo = itemData.value.logo_image.split("/")[1];
          let imageTypeLogo = itemData.value.logo_image.split(".")[1];

          let file = {
            size: 123,
            name: imageNameLogo,
            type: `image/${imageTypeLogo}`,
            accepted: true,
          };
          let url = imageLogoUrl + "?path=" + itemData.value.logo_image;
          dropzoneImageLogo.value.manuallyAddFile(file, url);
        }

        if (itemData.value.app_image) {
          let imageNameApp = itemData.value.app_image.split("/")[1];
          let imageTypeApp = itemData.value.app_image.split(".")[1];

          let file = {
            size: 123,
            name: imageNameApp,
            type: `image/${imageTypeApp}`,
            accepted: true,
          };
          let url = imageAppUrl + "?path=" + itemData.value.app_image;
          dropzoneImageApp.value.manuallyAddFile(file, url);
        }
      }
    };

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      imageUrl,
      imageLogoUrl,
      imageAppUrl,
      dropzoneOptions,
      dropzoneImage,
      addFileImage,
      removeFileImage,
      hasImage,
      dropzoneImageLogo,
      addFileImageLogo,
      removeFileImageLogo,
      hasImageLogo,
      dropzoneImageApp,
      addFileImageApp,
      removeFileImageApp,
      hasImageApp,
    };
  },
};
</script>

<style lang="scss">
.dz-preview {
  margin: 0 !important;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.dropzone .dz-preview .dz-image img {
  width: 15vw !important;
  height: 15vw !important;
}

.dropzone-main {
  width: 15vw;
  height: 15vw;
  margin: auto;
  padding: 0;
  background-position: center;
  background-size: cover;
}

.dz-remove {
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.vue-dropzone > .dz-preview:hover .dz-remove {
  margin: 0;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: none !important;
  transform: none !important;
}

.dz-size {
  text-align: center;
}

.dz-message {
  margin: 0 !important;
}
</style>
