<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $tc('actions.filter', 1) }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label>Master Tenant</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="masterTenantFilter"
            :options="masterTenantOptions" class="w-100" multiple :reduce="val => val.value"
            @input="(val) => $emit('update:masterTenantFilter', val)" data-cy="masterTenant"/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    masterTenantOptions: {
      type: Array,
      default: null,
    },
    masterTenantFilter: {
      type: Array,
      default: null,
    },
  },
}
</script>